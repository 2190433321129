import React, { useEffect, useState } from "react";
import "../Assets/Style/custom-styles/custom-styles.css";
const storyblokAPI = process.env.GATSBY_APP_STORYBLOK_TOKEN;
const storyblokVersion = process.env.GATSBY_APP_STORYBLOK_VERSION;

const Cities = () => {
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const onClickGetData = (e) => {
    e.preventDefault();
    setFlag(true);
  };

  useEffect(() => {
    const api =
      "https://api.storyblok.com/v2/cdn/stories?starts_with=insureonline/city-pages/states/illinois&token=" +
      storyblokAPI +
      "&version=" +
      'draft';
    fetch(api)
      .then((response) => response.json())
      .then((json) => {
        setData(json.stories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [flag]);
  return (
    <div className="main-wrapper">

      <div className="auto-insurance-section">
        <div className="container">
          <div className="row">
            {/* <div>
              <button onClick={onClickGetData}> Click Here To Load Data</button>
            </div> */}
            <div className="col-sm-6 col-md-4">
              {data?.map((item) => {
                return (
                  <>
                    <div>
                      <a
                        // href={`/il/${
                        //   item.full_slug
                        //     .split("states")[1]
                        //     .split("meta-data")[0]
                        //     .split("/")[2]
                        // }`}
                      >
                        <div className="online-quote">
                          <span>
                            {
                              item.full_slug.split('illinois/')[1].split('/article-section')[0]
                                // .split("states")[1]
                                // .split("meta-data")[0]
                                // .split("/")[2]
                            }
                          </span>
                        </div>
                      </a>
                    </div>
                  </>
                );
              })}
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cities;
